<template>
  <div class="home" style="margin-top: 5px">
    <van-notice-bar wrapable :scrollable="false" :text="userTips" />
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        required
        name="area"
        :value="areaValue"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
        :error-message="countyError"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          value="350503"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        required
        name="estate"
        label="小区选择"
        readonly
        :value="estateValue"
        :error-message="estateError"
      >
        <template #input>
          <el-select
            required
            style="width: 100%"
            v-model="value"
            filterable
            remote
            suze="mini"
            clearable
            reserve-keyword
            @change="handleChange"
            placeholder="输入小区名称"
            :remote-method="remoteEstateMethod"
            :loading="e_loading"
          >
            <el-option
              v-for="item in data"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        required
        name="building"
        :value="building"
        label="楼幢选择"
        placeholder="点击选择楼幢"
        @click="funcshowBuilding"
      />
      <van-popup v-model="showBuilding" position="bottom">
        <van-picker
          title="选择楼幢"
          show-toolbar
          :columns="buildingList"
          @confirm="onConfirmBuilding"
          @cancel="showBuilding = false"
        />
      </van-popup>
      <van-field
        required
        name="room"
        label="房号选择"
        readonly
        clickable
        placeholder="点击选择房号"
        :value="room"
      >
        <template #input>
          <el-select
            @focus="funcshowRoom"
            clearable
            style="width: 100%"
            v-model="room"
            filterable
            placeholder="输入房号筛选"
            @change="onConfirmRoom"
          >
            <el-option
              v-for="item in roomList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </van-field>
      <!-- <van-popup v-model="showRoom" position="bottom">
        <van-picker
          title="选择房号"
          show-toolbar
          :columns="roomList"
          @confirm="onConfirmRoom"
          @cancel="showRoom = false"
        />
      </van-popup> -->
      <van-field
        required
        v-model="roomArea"
        name="roomArea"
        type="number"
        label="面积(㎡)"
        placeholder="房号选择完若面积为空,请手动输入."
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <van-popup v-model="show" round closeable style="width: 90%; height: 90%">
      <div
        style="
          text-align: center;
          font-weight: bold;
          line-height: 18px;
          font-size: 15px;
          margin-top: 5%;
        "
      >
        询价结果
      </div>
      <van-divider
        style="margin-bottom: 0 !important; margin-top: 5px !important"
      ></van-divider>
      <van-cell title="区域" :value="areaValue"></van-cell>
      <van-cell title="名称" :value="eName"></van-cell>
      <van-cell title="楼幢" :value="bNo" />
      <van-cell title="房号" :value="rNo" />
      <van-cell title="面积" :value="buildingArea"></van-cell>
      <van-cell title="抵押单价">
        <template #default>
          <span style="color: red; font-weight: bold">{{ unitPrice }}</span>
          元/平方米
        </template>
      </van-cell>
      <van-cell title="抵押总价">
        <template #default>
          <span style="color: red; font-weight: bold">{{ totalPrice }}</span>
        </template>
      </van-cell>
      <van-divider style=""
        ><span style="font-weight: bold; color: red"
          >温馨提示</span
        ></van-divider
      >
      <template>
        <div style="text-align: left; margin: 0 10px; font-size: 13px">
          <div style="mrgint: 10px 0">
            ＊ 此数据仅供参考,不作最终确定额度的正式依据。
          </div>
          <div style="mrgint: 10px 0">
            ＊ 若需正式报告或有合作需求,请联系刘女士:<van-icon name="phone" /><a
              href="tel:19959776898"
              >19959776898</a
            >。
          </div>
        </div>
      </template>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import AreaList from "../assets/Area.json";
import querystring from "querystring";
import qs from "qs";
let timeout;
let currentValue;
let roomtimeout;
let roomcurrentValue;

export default {
  name: "Home",
  data() {
    return {
      areaValue: "福建省-泉州市-丰泽区",
      estateValue: "",
      showArea: false,
      showBuilding: false,
      showRoom: false,
      show: false,
      targetDetailShow: false,
      countyCode: "350503",
      cityCode: "350500",
      provinceCode: "350000",
      areaList: AreaList,
      building: "",
      buildingValue: "",
      buildingList: [],
      room: "",
      uId: "",
      roomValue: "",
      roomList: [],
      estateList: "",
      data: [],
      value: undefined,
      e_loading: false,
      roomArea: "",
      countyError: "",
      estateError: "",
      areaError: "",
      userTips: "",
      eName: "",
      bNo: "",
      rNo: "",
      eId: "",
      bId: "",
      rId: "",
      address: "",
      year: "",
      floor: "",
      totalPrice: "",
      createdAt: "",
      unitPrice: "",
      buildingArea: "",
      feId: "",
      feType: "",
      ifSrc: "",
      propertyName: "",
      developer: "",
      limitNum: "",
      structure: "",
      upFloorArea: "",
      downFloorArea: "",
      usedWays: "",
      physicalFloor: "",
      orientation: "",
      roomUnit: "",
      showNumKeyboard: false,
    };
  },
  created() {
    sessionStorage.setItem("user_open_id", this.$route.query.openid);
  },
  mounted() {
    // this.loginCheck();
    this.getUserInfo();
    this.remove_readonly();
  },
  methods: {
    getUserInfo() {
      var that = this;
      this.$axios
        .get("/center")
        .then(
          function (response) {
            if (response.code == "500") {
              that.$toast.fail(response.msg);
              that.$router.push(response.url);
            } else {
              that.userTips =
                response.data.mobile +
                "，剩余" +
                response.data.authorize_times +
                "次，" +
                response.data.authorize_end_times +
                "授权到期!";
              that.uId = response.data.u_id;
            }
          },
          function (err) {
            console.log(err.response);
          }
        )
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    },
    handleSearch(value) {
      var that = this;
      this.fetch(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
    },
    handleChange(value) {
      this.value = value;
      this.estateValue = value;
      var that = this;
      this.remoteEstateMethod(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
      this.$axios.get("/building?estate=" + value).then(
        function (response) {
          that.buildingList = response.data;
          that.building = "";
          that.buildingValue = "";
          that.room = "";
          that.roomValue = "";
          that.roomArea = "";
        },
        function (err) {}
      );
    },
    //>小区名搜索
    remoteEstateMethod(value, callback) {
      var that = this;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      function fake() {
        const str = querystring.encode({
          code: "utf-8",
          q: value,
          area: that.countyCode,
        });
        that.$axios.get("/estate?" + str).then(
          function (response) {
            if (currentValue === value) {
              const data = [];
              var result = response.data;
              result.forEach((r) => {
                data.push({
                  value: r.id,
                  text: r.text,
                });
              });
              that.data = data;
            }
          },
          function (err) {}
        );
      }
      timeout = setTimeout(fake, 300);
    },
    //>房号搜索
    // handleSearchRoom(value) {
    //   var that = this;
    //   this.fetchRoom(value, (data) => (this.data = data));
    //   // fetch(value, (data) => (this.data = data));
    // },
    handleChangeRoom(value) {
      console.log(value);
      this.room = value;
      this.roomValue = value;
      var that = this;
      this.fetchRoom(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
      this.$axios.get("/room_area?room_id=" + this.roomValue).then(
        function (response) {
          that.roomArea =
            response.data == 0 || response.data == null ? null : response.data;
        },
        function (err) {}
      );
    },
    // fetchRoom(value, callback) {
    //   var that = this;
    //   if (timeout) {
    //     clearTimeout(timeout);
    //     timeout = null;
    //   }
    //   roomcurrentValue = value;

    //   function fake() {
    //     const str = querystring.encode({
    //       code: "utf-8",
    //       q: value,
    //       building: that.buildingValue,
    //     });
    //     that.$axios.get("/room?" + str).then(
    //       function (response) {
    //         if (roomcurrentValue === value) {
    //           const data = [];
    //           var result = response.data;
    //           result.forEach((r) => {
    //             data.push({
    //               value: r.id,
    //               text: r.text,
    //             });
    //           });
    //           that.roomList = data;
    //         }
    //       },
    //       function (err) {}
    //     );
    //   }
    //   roomtimeout = setTimeout(fake, 300);
    // },
    //>省市区确认
    onConfirm(values) {
      this.areaValue = values.map((item) => item.name).join("-");
      this.countyCode = values[2].code;
      this.cityCode = values[1].code;
      this.provinceCode = values[0].code;
      this.showArea = false;
    },
    //>楼幢选择确认
    onConfirmBuilding(values) {
      this.building = values.text;
      this.buildingValue = values.value;
      this.showBuilding = false;
      this.roomList = [];
      var that = this;
      this.$axios.get("/room?building=" + values.value).then(
        function (response) {
          that.roomList = response.data;
        },
        function (err) {}
      );
      this.room = "";
      this.roomValue = "";
      this.roomArea = "";
    },
    //>房号选择确认
    onConfirmRoom(values) {
      console.log(values);
      this.room = values;
      this.roomValue = values;
      var that = this;
      this.$axios.get("/room_area?room_id=" + this.roomValue).then(
        function (response) {
          that.roomArea =
            response.data == 0 || response.data == null ? null : response.data;
        },
        function (err) {}
      );
      this.showRoom = false;
    },
    funcshowBuilding() {
      if (!this.estateValue) {
        this.$toast({
          message: "请先选择小区",
          icon: "warn-o",
          position:'top'
        });
        return;
      }
      this.showBuilding = true;
    },
    funcshowRoom() {
      if (!this.estateValue) {
        this.$toast({
          message: "请先选择小区",
          icon: "warn-o",
          position:'top'
        });
        return;
      }
      if (!this.building) {
        this.$toast({
          message: "请先选择楼幢",
          icon: "warn-o",
          position:'top'
        });
        return;
      }
    },
    //>提交
    onSubmit(values) {
      values.building = this.buildingValue;
      values.room = this.roomValue;
      values.uId = this.uId;
      values.countyCode = this.countyCode;
      values.cityCode = this.cityCode;
      values.provinceCode = this.provinceCode;
      var that = this;
      this.$axios.post("/evaluate", qs.stringify(values)).then(
        function (response) {
          if (response.data.code == "500") {
            that.$toast({
              message: response.data.msg,
              icon: "warn-o",
            });
            that.$router.push(res.data.url);
            return;
          } else {
            var those = that;
            that.$axios.get("/evaluator_detail?fe_id=" + response.data.id).then(
              function (res) {
                that.show = true;
                those.eId = res.data.e_id;
                those.bId = res.data.b_id;
                those.rId = res.data.r_id;
                those.eName = res.data.e_name;
                those.bNo = res.data.building_no;
                those.rNo = res.data.room_no;
                those.address =
                  res.data.address === null ? "暂无数据" : res.data.address;
                those.year = res.data.building_year;
                those.floor =
                  res.data.logic_floor > 0 ? res.data.logic_floor : "暂无数据";
                those.totalPrice = res.data.total_price;
                those.createdAt = res.data.created_at;
                those.unitPrice = res.data.unit_price;
                those.buildingArea =
                  res.data.building_area > 0
                    ? res.data.building_area
                    : "暂无数据";
              },
              function (err) {
                console.log(err);
              }
            );
          }
        },
        function (err) {}
      );
    },
    jumpDetail(id, type) {
      this.targetDetailShow = true;
      this.feId = id;
      this.feType = type;
      this.ifSrc =
        "https://www.321pg.com/mobile/mobile_map?id=" + id + "&type=" + type;
      var that = this;
      this.$axios.get("/target_detail?id=" + id + "&type=" + type).then(
        function (res) {
          if (type === "estate") {
            that.propertyName = res.data.property_name;
            that.developer = res.data.developer;
          }
          if (type === "building") {
            that.limitNum = res.data.limit_num > 0 ? "是" : "否";
            that.structure = res.data.structure;
            that.upFloorArea =
              res.data.up_floor_area > 0 ? res.data.up_floor_area : "暂无数据";
            that.downFloorArea =
              res.data.down_floor_area > 0
                ? res.data.down_floor_area
                : "暂无数据";
          }
          if (type === "room") {
            that.usedWays = res.data.used_ways;
            that.physicalFloor =
              res.data.physical_floor > 0
                ? res.data.physical_floor
                : "暂无数据";
            that.orientation =
              res.data.orientation === null ? "暂无数据" : res.data.orientation;
            that.roomUnit =
              res.data.room_unit === null ? "暂无数据" : res.data.room_unit;
          }
        },
        function (err) {}
      );
    },
    remove_readonly() {
      Array.from(document.getElementsByClassName("el-select")).forEach(
        (item) => {
          item.children[0].children[0].removeAttribute("readOnly");
          item.children[0].children[0].onblur = function () {
            let _this = this;
            setTimeout(() => {
              _this.removeAttribute("readOnly");
            }, 200);
          };
        }
      );
    },
  },
};
</script>
<style>
.ant-select-selection {
  border: 1px solid white !important;
}
.ant-select-selection__rendered {
  margin-left: 0 !important;
}
.van-cell {
  text-align: left;
}
</style>