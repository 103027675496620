<template>
  <div>
    <div
      style="font-weight:700;font-size:30px;text-align:center;margin-bottom:35px;margin-top:45%;"
    >瑞房智评</div>
    <div class="login_logo_border_bottom"></div>
    <div class="login_wechat_login">
      <a href="https://www.321pg.com/postbank/wechat">
        <svg class="icon" aria-hidden="true" style="font-size:50px;color:forestgreen;">
          <use xlink:href="#icon-weixin-copy" />
        </svg>
        <div class="login_wechat_login_font">微信登录</div>
      </a>
    </div>
    <div class="web-footer" style="bottom:0;padding-bottom:0;">
      <p>
        Copyright © 2020-2021
        <a href="/">瑞房智评</a>
      </p>
      <p>
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="nofollow"
          style="color:black;text-decoration:none;"
        >闽ICP备13011148号-3</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    
  },
  methods:{
    //  wechatLogin(){
    //    var that = this;
    //    this.$axios.get('/wechat').then(function(res){
    //      console.log(res);
    //       if(res.data.code == '000'){
    //          console.log(res);
    //       }
    //    },function(err){
    //      console.log(err);
    //    });
    //  }
  }
};
</script>
<style scoped>
svg {
  position: relative !important;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.login_logo {
  margin: 0 auto;
  text-align-last: left;
}
.login_logo > img {
  width: 50%;
  margin-left: 15px;
}
.login_logo_border_bottom {
  border-bottom: 1px solid black;
  width: 80%;
  margin: 0 auto 30px auto;
}
.login_wechat_login {
  border-radius: 50%;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  color: white;
  text-align: center;
}
.login_wechat_login_font {
  padding-top: 5px;
  font-size: 0.3rem;
  font-weight: 700;
  color:black;
}
a {
  color: #111;
  text-decoration: none;
}
.web-footer {
  position: absolute;
  width: 100%;
  /* height: 40px; */
  bottom: 70px;
  left: 0;
  text-align: center;
  color: #999;
  z-index: 2;
  padding-bottom: 10px;
  font-size: 0.3rem;
}
</style>