<template>
  <div class="history">
    <van-sticky>
      <form action="/">
        <van-search
          v-model="searchData"
          shape="round"
          show-action
          @search="onSearch"
          @cancel="onCancel"
          placeholder="输入小区名搜索"
        />
      </form>
    </van-sticky>
    <van-empty description="暂无查询记录" v-show="emptyList" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        style="margin-bottom: 10%"
        v-model="loading"
        :finished="finished"
        finished-text=" —— 我也是有底线的 —— "
        @load="onLoad"
        :offset="10"
      >
        <el-card
          class="box-card"
          style="margin-bottom: 10px; margin-top: 5px"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div slot="header" class="clearfix" style="margin-bottom: 10px">
            <span style="float: left; color: gray; font-weight: bolder">
              {{ item.target_name }}
              <el-button type="success" size="mini" style="padding: 3px" @click="contactUs(item.fe_id)"
                >评估合作</el-button
              >
            </span>
            <el-button
              style="float: right; padding: 3px"
              type="primary"
              size="mini"
              @click="detail(item.fe_id)"
              >楼盘信息</el-button
            >
          </div>
          <div class="text item" style="text-align: left; color: gray">
            <div style="margin-bottom: 5px">
              询价时间
              <span style="margin-left: 15px; font-weight: bolder">{{
                item.created_at
              }}</span>
            </div>
            <div style="margin-bottom: 5px">
              查询单价
              <span style="margin-left: 15px; font-weight: bolder"
                >{{ item.unit_price }}/平方米</span
              >
            </div>
            <div style="margin-bottom: 5px">
              查询总价
              <span style="margin-left: 15px; font-weight: bolder">{{
                item.total_price
              }}</span>
            </div>
            <van-notice-bar
              color="#1989fa"
              background="#ecf9ff"
              left-icon="info-o"
              style="font-size: 9px"
            >
              结果仅供参考,非确定贷款额度的正式依据.若需合作,请点击[评估合作]按钮与我们联系!
            </van-notice-bar>
          </div>
        </el-card>
      </van-list>
    </van-pull-refresh>
    <van-popup closeable round close-icon="close" v-model="contactShow" style="width:90%;">
      <van-cell-group style="margin-top:30px;">
        <van-cell title="姓名" :value="contactUsInfo.name" />
        <van-cell title="手机" label="点击号码联系我们">
          <template #default>
            <a :href="'tel:'+contactUsInfo.mobile" style="color:gray;font-weight:bolder;">{{contactUsInfo.mobile}}</a>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>
    <van-popup
      v-model="show"
      closeable
      round
      close-icon="close"
      @close="popClose"
      style="width: 95%; height: 85%"
    >
      <div style="max-height: 75%;">
        <van-tabs v-model="activeName" swipeable>
          <van-tab title="楼盘信息" name="info">
            <div style="max-height: 480px; overflow-y: scroll; margin-top: 5px;">
              <van-cell-group title="小区信息">
                <van-cell title="小区名称" :value="popupForm.eName" />
                <van-cell title="小区别名" :value="popupForm.eAlias" />
                <van-cell title="小区幢数" :value="popupForm.totalBuilding" />
                <van-cell title="开发商" :value="popupForm.developer" />
                <van-cell title="物业公司" :value="popupForm.propertyName" />
                <van-cell title="物业服务" :value="popupForm.propertyService" />
              </van-cell-group>
              <van-cell-group title="楼幢信息">
                <van-cell title="楼幢编号" :value="popupForm.bNo" />
                <van-cell title="建筑年代" :value="popupForm.year" />
                <van-cell title="地上层数" :value="popupForm.upFloor" />
                <van-cell title="地下层数" :value="popupForm.downFloor" />
                <van-cell title="电梯数" :value="popupForm.limitNum" />
                <van-cell
                  title="楼幢位置"
                  :value="popupForm.buildingPosition"
                />
                <van-cell title="楼幢朝向" :value="popupForm.orientation" />
                <van-cell title="楼幢状态" :value="popupForm.buildingStatus" />
              </van-cell-group>
              <van-cell-group title="房屋信息">
                <van-cell title="房号" :value="popupForm.rNo" />
                <van-cell title="套内层数" :value="popupForm.inFloor" />
                <van-cell title="逻辑楼层" :value="popupForm.floor" />
                <van-cell title="物理楼层" :value="popupForm.physicalFloor" />
                <van-cell title="建筑面积" :value="popupForm.buildingArea" />
              </van-cell-group>
            </div>
          </van-tab>
          <van-tab title="楼盘图片" name="images">
            <div style="max-height:480px;overflow-y: scroll; margin-top: 5px;">
            <van-empty
              style="margin-top: 20px"
              v-if="popupForm.imgList.length < 1"
              class="custom-image"
              image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
              description="暂无图片"
            />
            <div style="max-height: 565px; overflow-y: scroll; margin-top: 5px">
              <lazy-component style="margin-top: 5px">
                <img
                  v-for="(img, key) in popupForm.imgList"
                  v-lazy="img"
                  style="width: 95%; height: 100%; margin-bottom: 30px;border-radius:5px;"
                  :key="key"
                />
              </lazy-component>
            </div>
            </div>
          </van-tab>
          <van-tab title="地图导航" name="map">
            <div style="margin-top: 5px; max-height: 500px;">
              <div style="margin: 0 0 10px 0; width: 100%; max-height: 565;">
                <div
                  style="
                    height: 500px;
                    width: 100%;
                    font-weight: bold;
                    text-align: center;
                  "
                  class="map"
                >
                  <iframe
                    frameborder="no"
                    border="0"
                    style="width: 97%; min-height: 520px; margin: 0 auto;border-radius:15px;"
                    :src="popupForm.ifSrc"
                  ></iframe>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "History",
  data() {
    return {
      emptyList: false,
      listData: [],
      loading: false,
      finished: false,
      refreshing: false,
      list: 0,
      page: 1,
      show: false,
      contactShow:false,
      targetDetailShow: false,
      eId: "",
      bId: "",
      rId: "",
      totalPrice: "",
      createdAt: "",
      unitPrice: "",
      feId: "",
      feType: "",
      searchData: "",
      activeName: "info",
      popupForm: {
        imgList: [],
        upFloor: "",
        downFloor: "",
        upFloorArea: "",
        downFloorArea: "",
        usedWays: "",
        physicalFloor: "",
        orientation: "",
        roomUnit: "",
        ifSrc: "",
        propertyName: "",
        propertyService: "",
        developer: "",
        limitNum: "",
        inFloor: "",
        buildingPosition: "",
        buildingStatus: "",
        structure: "",
        eAlias: "",
        totalBuilding: "",
        eName: "",
        bNo: "",
        rNo: "",
        year: "",
        floor: "",
        buildingArea: "",
        address: "",
      },
      contactUsInfo:{
          name:'',
          mobile:'',
      },
    };
  },
  created() {
    // //创建组件时，加载第1页数据
    // this.getList('');
  },
  mounted() {
    // this.container = this.$refs.pop_container;
  },
  methods: {
    detail(fe_id) {
      this.show = true;
      var that = this;
      this.$axios.get("/evaluator_detail?fe_id=" + fe_id).then(
        function (res) {
          if(res.data.code == '500'){
            that.$toast({
                message: res.data.msg,
                icon: "warn-o",
              });
              that.$router.push(res.data.url);
              return;
          }
          let type = "estate";
          that.popupForm.ifSrc =
            "https://www.321pg.com/postbank/mobile_map?id=" +
            res.data.e_id +
            "&type=" +
            type;
          that.popupForm.eName = res.data.e_name;
          that.popupForm.bNo = res.data.building_no;
          that.popupForm.rNo = res.data.room_no;
          that.popupForm.inFloor =
            res.data.in_floor > 0 ? res.data.in_floor : "--";
          that.popupForm.upFloor =
            res.data.up_floor > 0 ? res.data.up_floor : "--";
          that.popupForm.downFloor =
            res.data.down_floor > 0 ? res.data.down_floor : "--";
          that.popupForm.limitNum =
            res.data.limit_num > 0 ? res.data.limit_num : "--";
          that.popupForm.address =
            res.data.address === null ? "--" : res.data.address;
          that.popupForm.year = res.data.building_year;
          that.popupForm.buildingStatus = !res.data.building_status
            ? "--"
            : res.data.building_status;
          that.popupForm.floor =
            res.data.logic_floor > 0 ? res.data.logic_floor : "--";
          that.popupForm.physicalFloor =
            res.data.physical_floor > 0 ? res.data.physical_floor : "--";
          that.popupForm.propertyService = !res.data.property_service
            ? "--"
            : res.data.property_service;
          that.popupForm.propertyName = !res.data.property_name
            ? "--"
            : res.data.property_name;
          that.popupForm.buildingPosition = res.data.building_position;
          that.popupForm.developer = !res.data.developer
            ? "--"
            : res.data.developer;
          that.popupForm.totalBuilding =
            res.data.total_building > 0 ? res.data.total_building : "--";
          that.popupForm.eAlias = !res.data.e_alias ? "--" : res.data.e_alias;
          that.popupForm.orientation = res.data.orientation;
          that.totalPrice = res.data.total_price;
          that.createdAt = res.data.created_at;
          that.unitPrice = res.data.unit_price;
          that.popupForm.buildingArea =
            res.data.building_area > 0 ? res.data.building_area : "--";
          that.popupForm.imgList = res.data.images;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    getList(estate) {
      var that = this;
      if (!estate || !this.searchData) {
        estate = this.searchData;
      }
      this.$axios
        .get("/list?limit=10&page=" + that.page + "&e_name=" + estate)
        .then(
          function (res) {
            if (res.data.code == "500") {
              that.refreshing = false;
              that.list = [];
              that.loding = false;
              that.emptyList = true;
              that.$toast({
                message: res.data.msg,
                icon: "warn-o",
              });
            }
            if (that.refreshing) {
              that.list = [];
              that.refreshing = false;
            }
            that.page++;
            if (
              JSON.stringify(that.listData) == JSON.stringify(res.data.data)
            ) {
              that.listData = res.data.data;
            } else {
              that.listData = that.listData.concat(res.data.data);
            }
            that.emptyList = that.listData.length === 0 ? true : false;
            that.list = res.data.num;
            that.loading = false;
            if (that.listData.length >= that.list) {
              that.finished = true;
            }
          },
          function (err) {
            console.log(err);
          }
        );
    },
    // 列表加载
    onLoad() {
      setTimeout(() => {
        this.getList();
        this.loading = true;
      }, 500);
    },
    onRefresh() {
      var that = this;
      setTimeout(() => {
        // 重新初始化这些属性
        that.loading = true;
        that.list = [];
        that.page = 1;
        that.loading = false;
        that.finished = false;
        that.emptyList = false;
        // 请求信息
        that.getList();
      }, 500);
    },
    jumpDetail(id, type) {
      this.targetDetailShow = true;
      this.feId = id;
      this.feType = type;
      this.ifSrc =
        "https://www.321pg.com/postbank/mobile_map?id=" + id + "&type=" + type;
      var that = this;
      this.$axios.get("/target_detail?id=" + id + "&type=" + type).then(
        function (res) {
          if (type === "estate") {
            that.propertyName = res.data.property_name;
            that.developer = res.data.developer;
          }
          if (type === "building") {
            that.limitNum = res.data.limit_num > 0 ? "是" : "否";
            that.structure = res.data.structure;
            that.upFloorArea =
              res.data.up_floor_area > 0 ? res.data.up_floor_area : "暂无数据";
            that.downFloorArea =
              res.data.down_floor_area > 0
                ? res.data.down_floor_area
                : "暂无数据";
          }
          if (type === "room") {
            that.usedWays = res.data.used_ways;
            that.physicalFloor =
              res.data.physical_floor > 0
                ? res.data.physical_floor
                : "暂无数据";
            that.orientation =
              res.data.orientation === null ? "暂无数据" : res.data.orientation;
            that.roomUnit =
              res.data.room_unit === null ? "暂无数据" : res.data.room_unit;
          }
        },
        function (err) {}
      );
    },
    onSearch(value) {
      this.list = [];
      this.page = 1;
      this.listData = [];
      this.getList(value);
    },
    onCancel(value) {
      this.list = [];
      this.page = 1;
      this.listData = [];
      this.getList(value);
    },
    scroll() {
      return {
        scrollTop: "5px",
        isFixed: true,
      };
    },
    popClose(value) {
      this.popupForm = {
        imgList: [],
        upFloor: "",
        downFloor: "",
        upFloorArea: "",
        downFloorArea: "",
        usedWays: "",
        physicalFloor: "",
        orientation: "",
        roomUnit: "",
        ifSrc: "",
        propertyName: "",
        propertyService: "",
        developer: "",
        limitNum: "",
        inFloor: "",
        buildingPosition: "",
        buildingStatus: "",
        structure: "",
        eAlias: "",
        totalBuilding: "",
        eName: "",
        bNo: "",
        rNo: "",
        year: "",
        floor: "",
        buildingArea: "",
        address: "",
      };
      this.activeName = "info";
    },
    contactUs(fe_id){
      var that = this;
      this.$axios.get('/contactor_get').then(function(res){
        that.contactShow = true;
        that.contactUsInfo.name = res.data.name;
        that.contactUsInfo.mobile = res.data.mobile;
      },function(err){
        console.log(err);
      })
    }
  },
};
</script>
<style scoped>
.history {
  margin-bottom: 10%;
}
.van-cell {
  text-align: left;
}
.van-cell-group__title {
  text-align: left;
  background-color: deepskyblue;
  font-weight: bold;
  color: white;
}
</style>