import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import 'lib-flexible/flexible'
import './plugins/vant.js'
import './assets/iconfont/iconfont.js'
import '../public/static/iconfont/iconfont.css'
// import {Select} from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css';
import 'vant/lib/index.css';
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './plugins/element.js';

// Vue.use(Select);

axios.defaults.baseURL = 'https://www.321pg.com/postbank/';
axios.defaults.withCredentials=true;
// axios.defaults.headers.origin = 'https://h5.321pg.com';
// axios.defaults.headers.token = "--------------------";

// Vue.config.productionTip = false
Vue.prototype.$axios= axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
