import Vue from 'vue'
import { Button } from 'vant'
import { NavBar } from 'vant'
import { Tabbar, TabbarItem } from 'vant';
import { NoticeBar } from 'vant';
import { Picker } from 'vant';
import { Icon } from 'vant';
import { Field } from 'vant';
import { Form } from 'vant';
import { Popup } from 'vant';
import { Area } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Empty } from 'vant';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { Grid, GridItem } from 'vant';
import { Image as VanImage } from 'vant';
import { Col, Row } from 'vant';
import { Card } from 'vant';
import { Toast } from 'vant';
import { NumberKeyboard } from 'vant';
import { Search } from 'vant';
import { Tab, Tabs } from 'vant';
import { Lazyload } from 'vant';
import { Sticky } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { Divider } from 'vant';
import { ContactCard } from 'vant';

Vue.use(ContactCard);
Vue.use(Divider);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Sticky);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Search);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Card);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Empty);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(NoticeBar);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);